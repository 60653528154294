:root {
  --backgroundColor: #C3E0E5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.homeContainer {
  text-align: center;
  min-height: 100vh;
  background-color: var(--backgroundColor);
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}

.driver {
  color: red;
  margin: auto;
  width: 100%;
  font-size: 35px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

.versus {
  color: red;
  margin: auto;
  width: 50%;
  font-size: 35px;
  padding: 0;
  text-align: center;
  cursor: default;
}

#title {
  background-color: var(--backgroundColor);
  margin: 0px;
  padding-top: 30px;
  padding-bottom: 15px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  color: lightsalmon;
}

.driverSelect {
padding: 30px 0;
}

.rules {
  width: 50%;
  display: inline-block;
  align-self: center;
  padding-top: 20px;
  text-align: left;
  font-size: 18px;
}

.rule {
  width: 100%;
  display: inline-block;
  align-self: center;
  padding-bottom: 40px;
  text-align: left;
}

.tracks {
  width: 25%;
  display: inline-block;
  align-self: center;
  text-align: left;
  font-size: 18px;
  padding: 50px;
  padding-top: 10px;
}

.track {
  color: red;
  margin: auto;
  width: 100%;
  font-size: 25px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}