.laps {
    -webkit-text-stroke-width: 0.25px;
    -webkit-text-stroke-color: black;
}

.start {
    display: inline-block;
    padding: 10px;
    font-size: 25px;
    color: #33a532;
    cursor: pointer;
    margin-bottom: 10px;
    font-style: emphasis;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
 }

 .forfeit {
    display: inline-block;
    padding: 10px;
    font-size: 25px;
    color: red;
    cursor: pointer;
    margin-bottom: 10px;
    font-style: emphasis;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: black;
 }


 .imgResp {
     display: inline-block;
     cursor: pointer;
     width: 100px;
     padding: 10px;
 }

 .imgDesc {
    display: flex;
    width: 150px;
    text-align: left;
    font-size: 12px;
}

 .imgContainer {
    display: inline-block;
    padding-bottom: 10px;
    text-align: center;
}

 .info {
     color: white;
     margin: auto;
     width: 100%;
     text-align: center;
     padding: 300px 0;
 }

 #overlay {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: table;
    background-color: rgba(0,0,0,0.95); /* Black background with opacity */
    z-index: 10; /* Specify a stack order in case you're using a different order for other elements */
  }

  .bottomBoxItem {
      display: inline-block;
      font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  .rightBox {
      padding-top: 15px;
      color: black;
      vertical-align: top;
      font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  .rightBoxItem {
    margin-top: 20px;
    text-decoration: underline;
    font-weight: bold;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    cursor: pointer;
}

.infoText {
    font-family: 'Courier New', Courier, monospace;
    padding-left: 10px;
}

.credits {
    color: white;
     margin: auto;
     width: 100%;
     text-align: center;
     padding: 300px 0;
}

.credits a {
    color: inherit;
}

.credits div {
    margin-top: 15px;
}

